<template>
    <svg
        width="165"
        height="165"
        viewBox="0 0 165 165"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>Connect to Peers</title>
        <g id="connect_1">
            <g id="Connect">
                <path
                    id="backgroundCircle"
                    d="M82.5 165C128.063 165 165 128.063 165 82.5C165 36.9365 128.063 0 82.5 0C36.9365 0 0 36.9365 0 82.5C0 128.063 36.9365 165 82.5 165Z"
                />
                <path
                    id="connectIcon"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M89 60C89 63.863 85.867 67 82 67C78.133 67 75 63.863 75 60C75 56.133 78.133 53 82 53C85.867 53 89 56.133 89 60ZM63 67C63 70.863 59.867 74 56 74C52.137 74 49 70.863 49 67C49 63.133 52.137 60 56 60C59.867 60 63 63.133 63 67ZM115 67C115 70.863 111.863 74 108 74C104.133 74 101 70.863 101 67C101 63.133 104.133 60 108 60C111.863 60 115 63.133 115 67ZM107 101.502C107 107.851 95.582 113 81.5 113C67.418 113 56 107.85 56 101.502C56 95.15 67.418 90 81.5 90C95.582 90 107 95.15 107 101.502ZM68 86.74C66.83 81.682 62.135 78 56.735 78C50.165 78 45 83.058 45 89.5V101H52.04C52.045 95.248 57.44 89.5 68 86.74ZM81.5 84.845C85.868 84.845 89.55 85.308 93 86V82.539C93 76.079 87.942 71 81.5 71C75.062 71 70 76.075 70 82.539V86C73.45 85.308 77.132 84.845 81.5 84.845ZM107.267 78C101.867 78 97.175 81.678 96 86.74C106.563 89.5 111.958 95.248 111.958 101H119V89.5C119 83.062 113.838 78 107.267 78Z"
                    fill="white"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "svg-connectToPeers"
};
</script>
