<template>
    <svg
        width="165"
        height="165"
        viewBox="0 0 165 165"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>Live Session and On Demand</title>
        <g id="sessionOnDemand">
            <g id="Play">
                <path
                    id="backgroundCircle"
                    d="M82.5 165C128.063 165 165 128.063 165 82.5C165 36.9365 128.063 0 82.5 0C36.9365 0 0 36.9365 0 82.5C0 128.063 36.9365 165 82.5 165Z"
                />
                <path
                    id="playIcon"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M98.019 84.018L72.706 98.457C72.226 99.025 71.401 99.167 70.756 98.79C70.111 98.414 69.834 97.63 70.102 96.938V68.062C69.834 67.371 70.111 66.586 70.756 66.21C71.401 65.833 72.226 65.975 72.706 66.543L98.02 80.982C98.618 81.253 99.001 81.846 99.001 82.5C99.001 83.155 98.618 83.748 98.02 84.02V84.018H98.019ZM122 82C122 92.61 117.784 102.784 110.284 110.284C102.784 117.784 92.609 122 82 122C71.391 122 61.216 117.784 53.716 110.284C46.216 102.784 42 92.609 42 82C42 71.391 46.216 61.216 53.716 53.716C61.216 46.216 71.391 42 82 42C92.609 42 102.784 46.216 110.284 53.716C117.784 61.216 122 71.391 122 82ZM116 82C116 72.984 112.418 64.337 106.042 57.958C99.664 51.582 91.016 48 82 48C72.984 48 64.337 51.582 57.958 57.958C51.582 64.336 48 72.984 48 82C48 91.016 51.582 99.663 57.958 106.042C64.336 112.418 72.984 116 82 116C91.016 116 99.663 112.418 106.042 106.042C112.418 99.664 116 91.016 116 82Z"
                    fill="white"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "svg-sessionOnDemand"
};
</script>
