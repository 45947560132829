<template>
    <svg
        width="165"
        height="165"
        viewBox="0 0 165 165"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>Posters</title>
        <g id="poster">
            <path
                id="backgroundCircle"
                d="M82.5 165C128.063 165 165 128.063 165 82.5C165 36.9365 128.063 0 82.5 0C36.9365 0 0 36.9365 0 82.5C0 128.063 36.9365 165 82.5 165Z"
            />
            <g id="posterIcons">
                <path
                    id="Vector"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M73.971 103H105.951C106.739 103 107.526 102.536 107.841 101.765C108.154 101.072 107.997 100.222 107.446 99.605L98.466 90.353C97.678 89.582 96.26 89.582 95.552 90.353L92.715 93.284L87.202 87.578C86.414 86.808 84.996 86.808 84.288 87.578L72.554 99.686C72.003 100.226 71.846 101.075 72.16 101.846C72.474 102.539 73.184 103 73.972 103H73.971ZM101 81.5C101 83.986 98.984 86 96.498 86C94.016 86 92 83.986 92 81.5C92 79.014 94.016 77 96.498 77C98.984 77 101 79.014 101 81.5Z"
                    fill="white"
                />
                <path
                    id="Vector_2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M117.026 63.363H64.526V57.641C64.526 52.233 60.5 47.53 55.29 47.059C52.37 46.747 49.526 47.687 47.395 49.646C45.265 51.606 44 54.35 44 57.172V107.81C44 107.89 44 108.594 44.08 108.83C44.632 113.925 49.292 118 54.738 118L117.026 117.997C118.13 117.997 119 117.133 119 116.037V65.323C119 64.224 118.13 63.363 117.026 63.363ZM50.078 52.623C51.42 51.447 53.078 50.899 54.895 51.055C58.053 51.367 60.502 54.27 60.502 57.638L60.499 99.733C57.026 97.069 51.657 97.069 48.027 99.733V57.169C48.027 55.445 48.737 53.721 50.078 52.622V52.623ZM114.972 113.998H54.738C51.342 113.998 48.422 111.567 48.108 108.432C47.95 106.628 48.502 104.984 49.686 103.649C50.87 102.317 52.53 101.609 54.266 101.609C56.872 101.609 59.16 103.177 60.106 105.529C60.501 106.549 61.606 107.176 62.713 106.94C63.659 106.784 64.606 105.685 64.606 104.669V67.359H115.052V114H114.972V113.998Z"
                    fill="white"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "svg-poster"
};
</script>
