



























































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import svgsessionOnDemand from "@/components/svg/svg-sessionOnDemand.vue";
import svgconnectToPeers from "@/components/svg/svg-connectToPeers.vue";
import svgexhibitor from "@/components/svg/svg-exhibitor.vue";
import svgposter from "@/components/svg/svg-poster.vue";

@Component({
    components: {
        "svg-session": svgsessionOnDemand,
        "svg-connect": svgconnectToPeers,
        "svg-exhibitor": svgexhibitor,
        "svg-poster": svgposter
    },
    filters: {
        maxCharacter(value: string) {
            return value.slice(0, 150) + "...";
        }
    }
})
export default class StaticPage extends Vue {
    @Prop({ type: Array, default: () => [] })
    staticPages!: [];

    @Prop({ default: "" })
    bottomStaticPage!: boolean;

    // created(){
    //     console.log(this.activeComponent())
    // }
    get getLayoutOptions() {
        return this.$store.getters.layoutOptions;
    }

    get getstaticCommunityExchange() {
        return this.$store.getters.getstaticCommunityExchange;
    }

    get getstaticExpectations() {
        return this.$store.getters.getstaticExpectations;
    }

    get myOwnProfileId() {
        return this.$store.getters.user.id;
    }
    get sectionToUse() {
        return this.$store.getters.getPageOptions("home").section.staticPage;
    }

    // activeComponent(component: string){
    //     console.log(component)
    //     return component
    // }
}
