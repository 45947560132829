<template>
    <svg
        width="165"
        height="165"
        viewBox="0 0 165 165"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>Exhibitors</title>
        <g id="exhibitor_icon">
            <path
                id="backgroundCircle"
                d="M82.5 165C128.063 165 165 128.063 165 82.5C165 36.9365 128.063 0 82.5 0C36.9365 0 0 36.9365 0 82.5C0 128.063 36.9365 165 82.5 165Z"
            />
            <g id="exhibitorIcon">
                <path
                    id="Fill 1"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M71.9996 64.4959C71.9967 61.1327 69.7555 58.1819 66.5163 57.2763C63.2772 56.3734 59.8331 57.7349 58.0881 60.6106C56.343 63.4864 56.7266 67.1727 59.0255 69.6274C61.3244 72.082 64.979 72.7051 67.9618 71.1533C70.4453 69.861 72.0029 67.2939 72 64.496L71.9996 64.4959ZM59.3078 64.4959C59.3078 62.396 60.5741 60.5039 62.5124 59.6991C64.4536 58.8944 66.6861 59.3386 68.1716 60.824C69.6571 62.3095 70.1013 64.5421 69.2965 66.4833C68.4918 68.4216 66.5996 69.6879 64.4997 69.6879C61.6326 69.6879 59.3078 67.3631 59.3078 64.4959Z"
                    fill="white"
                />
                <path
                    id="Fill 2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M64 74H63.9314L63 84L64 74Z"
                    fill="white"
                />
                <path
                    id="Fill 3"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M57.3271 70C53.7355 71.4778 51.0069 74.4915 51.0069 78.6052V98.0728C51.0069 100.01 50.7387 101.564 53.5548 101.564H55.0882V123.545C55.0882 125.703 56.0503 126 57.4204 126C57.9451 126 58.5282 125.959 59.1695 125.959C59.8109 125.959 60.3706 126 60.9187 126C62.3122 126 63.2509 125.703 63.2509 123.545V101.564H66.7491V123.545C66.7491 125.703 67.7112 126 69.0813 126C69.606 126 70.1891 125.959 70.8305 125.959C71.4718 125.959 72.0316 126 72.5796 126C73.9731 126 74.9118 125.703 74.9118 123.545V101.564H76.4452C79.2613 101.564 78.9931 99.9986 78.9931 98.0728V78.6171C78.9931 74.5037 76.2762 71.49 72.6846 70.0179C71.3116 71.679 69.4312 72.8484 67.3323 73.3459H67.3848L68.4984 83.6319C68.5392 84.0042 68.3963 84.3708 68.1194 84.6209L65.7872 86.7213C65.3412 87.1314 64.6532 87.1314 64.2072 86.7213L61.875 84.522C61.5981 84.269 61.4581 83.8995 61.5019 83.5272L62.6679 73.3573C60.5603 72.857 58.6712 71.6788 57.2981 70.0061L57.3271 70ZM69.0812 101.563H72.5795V123.672H71.874H70.8537H69.8333H69.0812V101.563ZM57.4204 101.563H60.9186V123.672H60.2131H59.1928H58.1725H57.4204V101.563Z"
                    fill="white"
                />
                <path
                    id="Fill 4"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M95.2853 75.2V68H85V80H101V76.4H96.4286C95.7971 76.4 95.2857 75.863 95.2857 75.2H95.2853Z"
                    fill="white"
                />
                <path
                    id="Fill 5"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M98.0003 65.6H102.572C102.875 65.6 103.166 65.726 103.38 65.951C103.595 66.176 103.715 66.482 103.715 66.8V74H114V62H98L98.0003 65.6Z"
                    fill="white"
                />
                <path
                    id="Fill 6"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M116.532 52H49.4688C47.5523 52 46 53.5577 46 55.4803V106.52C46 108.443 47.5523 110 49.4688 110H52.3594V104.159C51.2783 104.113 50.2521 103.675 49.4688 102.924C48.3125 101.694 48.3125 100.105 48.3125 98.8293V78.9932C48.3125 73.9589 51.498 70.2931 55.649 68.443C55.0044 67.1148 54.6691 65.6561 54.672 64.18C54.672 58.7339 59.0715 54.3203 64.5 54.3203C69.9284 54.3203 74.328 58.734 74.328 64.18C74.328 65.659 73.9898 67.1177 73.3452 68.4488C77.4904 70.3048 80.6874 73.9646 80.6874 78.993V98.3881V98.8173C80.6874 100.099 80.7279 101.717 79.5312 102.924C78.7478 103.675 77.7216 104.113 76.6406 104.159V110H116.531C118.448 110 120 108.442 120 106.52V55.48C120 53.5573 118.448 52 116.531 52H116.532ZM111.907 100.14H89.9374C89.2986 100.14 88.7811 99.6212 88.7811 98.9803C88.7811 98.3394 89.2986 97.8203 89.9374 97.8203H111.907C112.545 97.8203 113.063 98.3394 113.063 98.9803C113.063 99.6212 112.545 100.14 111.907 100.14ZM111.907 95.5003H89.9374C89.2986 95.5003 88.7811 94.9812 88.7811 94.3403C88.7811 93.6994 89.2986 93.1803 89.9374 93.1803H111.907C112.545 93.1803 113.063 93.6994 113.063 94.3403C113.063 94.9812 112.545 95.5003 111.907 95.5003ZM111.907 90.8603H89.9374C89.2986 90.8603 88.7811 90.3412 88.7811 89.7003C88.7811 89.0594 89.2986 88.5403 89.9374 88.5403H111.907C112.545 88.5403 113.063 89.0594 113.063 89.7003C113.063 90.3412 112.545 90.8603 111.907 90.8603ZM116.532 75.2002C116.532 75.5076 116.41 75.8034 116.193 76.0209C115.977 76.2385 115.682 76.3602 115.375 76.3602H103.813V81.0002C103.813 81.3076 103.691 81.6034 103.475 81.8209C103.258 82.0384 102.963 82.1602 102.657 82.1602H84.1564C83.5176 82.1602 83.0002 81.6411 83.0002 81.0002V67.0803C83.0002 66.4394 83.5176 65.9203 84.1564 65.9203H95.719V61.2804C95.719 60.6395 96.2364 60.1204 96.8753 60.1204H115.375C115.682 60.1204 115.977 60.2422 116.193 60.4597C116.41 60.6772 116.532 60.973 116.532 61.2804V75.2002Z"
                    fill="white"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "svg-exhibitor"
};
</script>
